<template>
    <v-content>
        <v-row>
            <v-col cols="1" lg="2"/>
                <v-col cols="10" lg="8">
                    <h3 align="center">{{überschrift}}</h3>
                    <v-divider v-if="überschrift != ''"></v-divider>
                    <p v-html="text">{{text}}</p>
                    </v-col>
                <v-col cols="1" lg="2"/>
        </v-row>
    </v-content>
</template>

<script>
export default {
    props:[
        'überschrift',
        'text'
    ]
}
</script>