<template>
    <v-content>
        <AnpassbareSeite :excelData="excelData"/>
        <v-container>
            <h3 align="center">Anfragen zum Leasing</h3>
            <Anfragen :anfrageArt="'zum Leasing'" />
        </v-container>
    </v-content>
</template>

<script>
import Anfragen from '../components/Anfragen'
import AnpassbareSeite from '../components/AnpassbareSeite.vue'
import $ from 'jquery'
export default {
  components: {
    Anfragen,
    AnpassbareSeite
    },
    data(){
        return{
            excelDataValue:""
        }
    },
    methods: {
      async aktualisieren(){
        var url = "https://sheets.googleapis.com/v4/spreadsheets/1YmLMMHUgaRV9GUSzhx8snUHJjw1MeXxthFmyVFK3sy4/values/Leasing?alt=json&key=AIzaSyDGr9rBnm8lpmMY6D_lk3lvWI6Nq0IwZWA"
        $.ajaxSetup({
        async: false
        });
        var jsony;
        await ($.getJSON(url, 'callback=?')).always(function(data) {
            jsony = data.values;
            jsony.shift()
        });
        this.excelDataValue = jsony;
      }
    },
    beforeMount(){
        this.aktualisieren()
    },
    computed: {
      excelData: function(){
        return this.excelDataValue
      }
    },
}
</script>