<template>
    <v-content >
        <v-row>
            <v-col cols="12"  md="4" align-content="center">
                <BildMitSpinner 
                :src=bildquelle1
                :height="höhe"
                :contain="true"
                />
            </v-col>
            <v-col cols="12" md="4" align-content="center">
                <BildMitSpinner 
                :src=bildquelle2
                :height="höhe"
                :contain="true"
                />
            </v-col>
            <v-col cols="4" class="hidden-sm-and-down" align-content="center">
                <BildMitSpinner
                :src=bildquelle3
                :height="höhe"
                :contain="true"
                />
            </v-col>
        </v-row>
    </v-content>
</template>

<script>
import BildMitSpinner from './BildMitSpinner.vue';
export default {
  components: { BildMitSpinner },
    props:[
        'bildquelle1',
        'bildquelle2',
        'bildquelle3',
        'höhe'
    ],
}
</script>