<template>
    <v-content>
        <v-row>
            <v-col cols="12" md="6">
                <v-container>
                    <h3 align="center"> {{titel}} </h3>
                    <p v-html="text">{{text}}</p>
                </v-container>
            </v-col>
            <v-col 
            cols="12" md="6">
                <v-row>
                    <v-container>
                        <BildMitSpinner 
                        :src=bildquelle
                        :aspectRatio="1"
                        :height="höhe"
                        :contain="true"
                        />
                    </v-container>
                </v-row>
                <v-row />

            </v-col>
        </v-row>
    </v-content>
</template>

<script>
import BildMitSpinner from './BildMitSpinner.vue'
export default {
  components: { BildMitSpinner },
    props:[
        'titel',
        'text',
        'bildquelle',
        'höhe'
    ]
}
</script>