<template>
  <div>
    <v-app>
    <!--:style="{
      'background-image':'url(https://www.michelbach-bilz.de/fileadmin/_migrated/pics/Kornfeld.jpg)',
      'background-size':'cover',
      'background-attachment':'fixed'
    }"-->
    <TheHeader />
      <router-view class="back"></router-view>
      <TheFooter />
    </v-app>
  </div>
</template>

<script>
import TheHeader from "./components/TheHeader"
import TheFooter from "./components/TheFooter"
export default {
  name: 'App',
  components: {
    TheHeader,
    TheFooter
  },
  data: () => ({
    
  }),
};
</script>

<style>
.back{
  position: relative;
}
.back::before{
  content: '';
  position: absolute;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  
  background-image: url("./assets/Kornfeld.jpg");
  background-size: cover;
  background-attachment: fixed;
  opacity: 0.2;
}
</style>
<!--background-image:url('https://www.michelbach-bilz.de/fileadmin/_migrated/pics/Kornfeld.jpg');-->