import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        produkte:"",
        spreadsheetURL: "https://sheets.googleapis.com/v4/spreadsheets/1_u0nhJIIazGP1I_hXSkE481JD2X8qykrWUhJ9Qm_KEc/values/Basics?alt=json&key=AIzaSyDGr9rBnm8lpmMY6D_lk3lvWI6Nq0IwZWA",
        spreadsheetURLInfoText: "https://sheets.googleapis.com/v4/spreadsheets/1iDRLZZQRwpDBWMh8oisZdBg-9lfBIGtP3HX2Ed4yaTo/values/InfoText!B2:B3?alt=json&key=AIzaSyDGr9rBnm8lpmMY6D_lk3lvWI6Nq0IwZWA",
    },
})