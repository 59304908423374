<template>
  <v-footer
    padless
    
  >
    <v-card
      class="flex"
      flat
      tile
      id="foot"
    >
      <v-layout justify-center>
        <v-btn
          v-for="icon in icons"
          :key="icon"
          class="mx-4"
          dark
          icon
          :href="icon.link"
        >
          <v-icon size="24px">
            {{ icon.ic }}
          </v-icon>
        </v-btn>
      </v-layout>
      <v-layout justify-center>
        Hühner und Schweinehof Kock
      </v-layout>
      <v-layout justify-center>
        <a :href="'/#/Impressum'" style="color:white"> Impressum </a>
      </v-layout>
      <v-layout justify-center>
        <a :href="'/#/Datenschutz'" style="color:white"> Datenschutz </a>
      </v-layout>
      <v-card-text class="py-2 white--text text-center">
        <!--{{ new Date().getFullYear() }} — <strong>Vuetify</strong>-->
        2020
      </v-card-text>
    </v-card>
  </v-footer>
</template>

<script>
  export default {
    data: () => ({
      icons: [
        {ic:'mdi-facebook', link:'https://www.facebook.com/pages/category/Agriculture/H%C3%BChner-und-Schweinehof-Kock-358192598128776/'},
        {ic:'mdi-instagram', link:'https://instagram.com/huehner_und_schweinehof_kock'},
        //'mdi-twitter',
        //'mdi-linkedin',
        //'mdi-instagram',
      ],
      bild: "./assets/Kornfeld-Hintergrund.jpg"
    }),
  }
</script>

<style scoped>
#foot{
 background-image: url('../assets/ws_Wheat_Field_Sunset_Forest_1920x1200.jpg');
  background-size: auto;
}
</style>