<template>
  <div>
    <v-toolbar
    id="head"
    dark
    >
    <!--prominent in v-toolbar-starter rein damit header dicker wird
    src="../assets/Feld-Sonnenuntergang.jpg"
    -->
      <v-spacer/>
      <!--src="https://cdn.icon-icons.com/icons2/37/PNG/512/pig_animal_4443.png"-->
      <v-img 
        :src="require('../assets/chicken_animal_4448.png')"
        height="10"
        aspect-ratio="1"
        contain
        dark
        class="hidden-sm-and-down"
        
        />
      <v-img 
        :src="require('../assets/chicken_animal_4448.png')"
        height="15"
        aspect-ratio="1"
        contain
        dark
        class="hidden-xs-only"
        />
      <v-img 
        :src="require('../assets/chicken_animal_4448.png')"
        height="20"
        aspect-ratio="1"
        contain
        dark
        class="hidden-xs-only"
        />
      <v-img 
        :src="require('../assets/pig_animal_4443.png')"
        height="40"
        aspect-ratio="1"
        contain
        dark
        />
      <v-img 
        :src="require('../assets/Logo_kock-01.png')"
        height="120"
        contain
        aspect-ratio="1"
        />
      <v-spacer/>
      <font size="6" class="hidden-xs-only">Hühner und Schweinehof Kock </font>
      <font size="4" class="hidden-sm-and-up">Hühner und Schweinehof Kock </font>
      <v-spacer />
      <v-img 
        :src="require('../assets/Logo_kock-01.png')"
        height="120"
        contain
        aspect-ratio="1"
        />
      <v-img 
        :src="require('../assets/pig_animal_4443_reverse.png')"
        height="40"
        aspect-ratio="1"
        contain
        dark
        />
      <v-img 
        :src="require('../assets/chicken_animal_4448_reverse.png')"
        height="20"
        aspect-ratio="1"
        contain
        dark
        class="hidden-xs-only"
        />
      <v-img 
        :src="require('../assets/chicken_animal_4448_reverse.png')"
        height="15"
        aspect-ratio="1"
        contain
        dark
        class="hidden-xs-only"
        
        />
      <v-img 
        :src="require('../assets/chicken_animal_4448_reverse.png')"
        height="10"
        aspect-ratio="1"
        contain
        dark
        class="hidden-sm-and-down"
        />
      <v-spacer/>
      <v-menu
            bottom
            right
            class="hidden-sm-and-up"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                dark
                icon
                v-bind="attrs"
                v-on="on"
                class="hidden-sm-and-up"
              >
                <v-icon x-large>mdi-menu</v-icon>
              </v-btn>
            </template>

            <v-list>
              <v-list-item
                v-for="(item, i) in items"
                :key="i"
                :to="item.link"
              >
                <v-list-item-title>{{ item.title }}</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
    </v-toolbar>

    <v-tabs
    fixed-tabs
    background-color="grey"
    class="hidden-xs-only"
    show-arrows
    center-active
    >
    <v-tab to="/">
      Startseite
    </v-tab>
    <v-tab to="/Preise">
      Preise
    </v-tab>
    <v-tab to="/BunteBentheimer">
        Bunte Bentheimer
    </v-tab>
    <v-tab to="/Leasing">
        Schweine-leasing
    </v-tab>
    <v-tab to="/Hofladen">
        Hofladen
    </v-tab>
    <v-tab to="/UnsereHofkisten">
        Hofkisten
    </v-tab>
    <!--v-tab to="/GeschehenAufDemHof">
        Geschehen auf dem Hof
    </v-tab-->
  </v-tabs>
  </div>
</template>

<script>
export default {
    name: "TheHeader",
    data(){
      return{
        items: [
        { title: 'Startseite' , link:"/"},
        { title: 'Preise' , link:"/Preise"},
        { title: 'Bunte Bentheimer' , link:"/BunteBentheimer"},
        { title: 'Schweine-Leasing' , link:"/Leasing"},
        { title: 'Hofladen' , link:"/Hofladen"},
        { title: 'Unsere Hofkisten' , link:"/UnsereHofkisten"},
        //{ title: 'Geschehen auf dem Hof' , link:"/GeschehenAufDemHof"},
      ],
      bild: require('../assets/pig_animal_4443.png'),
      }
    },
    components: [
    ],
    methods: {
    }
}
</script>

<style scoped>
router-link{
  color: black;
}
#head{
  background-image: url('../assets/ws_Wheat_Field_Sunset_Forest_1920x1200.jpg');
  background-size: auto;
}
</style>