<template>
    <div class="text-center">
        <v-img
            :src="src"
            :height="imgHeight"
            :aspect-ratio="aspectRatio"
            :class="classes"
            :contain="contain"
            @load="loaded = true"
            @error="imageLoadingErrored"
        />
        <v-progress-circular
            v-if="!loaded && !errored"
            indeterminate
            color="primary"
            size="50"
        ></v-progress-circular>
        <p v-if="errored">
            Sorry, es gab ein Problem beim Laden des Bildes.
            Wenn du die Webseite im Browser Safari aufgerufen hast, könnte ein wechsel zu Chrome oder Firefox helfen.
        </p>
    </div>
</template>

<script>
import * as Sentry from "@sentry/vue";
export default {
    props: ['src', 'height', 'aspectRatio', 'classes', 'contain'],
    data: ()=>({
        loaded: false,
        errored: false
    }),
    computed: {
        imgHeight: function(){
            return this.loaded ? this.height : 0
        }
    },
    methods:{
        imageLoadingErrored(err) {
            this.errored = true
            Sentry.captureException(err);
            Sentry.captureMessage("Bild hat nicht geladen.");
        }
    },
}
</script>