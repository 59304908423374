import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify';
import VueRouter from 'vue-router';
import store from './store';
import * as Sentry from "@sentry/vue";
import { BrowserTracing } from "@sentry/tracing";

import Startseite from './views/Startseite';
// import UnserSchweinefleisch from './views/UnserSchweinefleisch'
// import UnserSchweinefleisch_NochVerfügbar from './views/UnserSchweinefleisch_NochVerfügbar';
// import UnserSchweinefleisch_Reservieren from './views/UnserSchweinefleisch_Reservieren';
import Preise from './views/Preise';
import BunteBentheimer from './views/BunteBentheimer';
import BunteBentheimer_Warum from './views/BunteBentheimer_Warum';
import SchweineLeasing from './views/SchweineLeasing';
import Hofladen from './views/Hofladen';
import Hofkisten from './views/Hofkisten';
import TestSeite from './views/TestSeite';
//import GeschehenAufDemHof from './views/GeschehenAufDemHof';
import Impressum from './views/Impressum';
import Datenschutz from './views/Datenschutz';


Vue.use(VueRouter)

Vue.config.productionTip = false

const router = new VueRouter({
  routes: [
    {path: '/', component: Startseite, name:'Hühner und Schweinehof Kock'},
    {path: '/Preise', component: Preise},
    // {path: '/UnserSchweinefleisch/NochVerfügbar', component: UnserSchweinefleisch_NochVerfügbar},
    // {path: '/UnserSchweinefleisch/Reservieren', component: UnserSchweinefleisch_Reservieren},
    {path: '/BunteBentheimer', component: BunteBentheimer},
    {path: '/BunteBentheimer/WarumBentheimer', component: BunteBentheimer_Warum},
    {path: '/Leasing', component: SchweineLeasing},
    {path: '/Hofladen', component: Hofladen},
    {path: '/UnsereHofkisten', component: Hofkisten},
    {path: '/Test', component: TestSeite},
    //{path: '/GeschehenAufDemHof', component: GeschehenAufDemHof},
    {path: '/Impressum', component: Impressum},
    {path: '/Datenschutz', component: Datenschutz}
  ]
})

if(process.env.NODE_ENV == "production"){
  Sentry.init({
    Vue,
    dsn: "https://325130e83bec4770bac6b6d7b0f2f2ab@o1151605.ingest.sentry.io/6228529",
    integrations: [
      new BrowserTracing({
        routingInstrumentation: Sentry.vueRouterInstrumentation(router),
      }),
    ],
    environment: process.env.NODE_ENV,
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
  });
}

new Vue({
  store,
  router,
  vuetify,
  render: h => h(App)
}).$mount('#app')
