<template>
  <v-content>
    <v-tabs
      fixed-tabs
      background-color="transparent"
      heigth="10"
      hide-slider
      class="hidden-xs-only"
    >
      <v-tab v-for="(item,i) in items" :key="i"
      :to="item.link">
          {{item.name}}
      </v-tab>
    </v-tabs>

<v-container class="hidden-sm-and-up">
    <div class="text-center">
    <v-menu offset-y
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          v-bind="attrs"
          v-on="on"
          block
          color="white"
        >
          <v-icon >mdi-menu</v-icon>
          Mehr
          <v-icon> mdi-menu-down </v-icon>
        </v-btn>
      </template>
      <v-list>
        <v-list-item
          v-for="(item, index) in items"
          :key="index"
          :to="item.link"
        >
          <v-list-item-title>{{ item.name }}</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
  </div>
</v-container>
  </v-content>
</template>

<script>
export default {
    name: "HeaderErweiterer",
    props:['items']
}
</script>