<template>
  <v-row class="hidden-xs-only">
    <v-col
      v-for="(item,index) in items"
      :key="index"
      class="d-flex child-flex"
      cols="6"
      lg="4"
    >
      <BildMitSpinner
        :src=item
        aspectRatio="1"
        classes="grey lighten-2"
      />
    </v-col>
  </v-row>
</template>

<script>
import BildMitSpinner from './BildMitSpinner.vue'
export default {
  components: { BildMitSpinner },
    props:['items']
}
</script>