<template>
    <v-form
    ref="form"
    v-model="valid"
    lazy-validation
  >
    <v-row class="hintergrund">
        <v-col cols="12" md="6">
            <v-text-field
            v-model="vorname"
            :rules="vornameRules"
            label="Vorame"
            required
            ></v-text-field>

            <v-text-field
            v-model="nachname"
            :rules="nachnameRules"
            label="Nachname"
            required
            ></v-text-field>

            <v-text-field
            v-model="email"
            :rules="emailRules"
            label="E-Mail oder Handynummer"
            required
            ></v-text-field>
        </v-col>
        <v-col cols="12" md="6">
             <v-textarea
                name="input-7-1"
                filled
                label="Anfragen oder Anmerkungen"
                no-resize
                background-color="grey"
                required
                v-model="anfrage"
                ></v-textarea>
                <v-btn
                  :disabled="!valid"
                  color="grey"
                  class="mr-4"
                  @click="validate"
                  >
                    Absenden
                </v-btn>
                <v-snackbar
                  v-model="snackbar"
                  :timeout="5000"
                  centered
                >
                  Die Anfrage wurde abgeschickt

                  <template v-slot:action="{ attrs }">
                    <v-btn
                      color="blue"
                      text
                      v-bind="attrs"
                      @click="snackbar = false"
                    >
                      Close
                    </v-btn>
                  </template>
                </v-snackbar>
        </v-col>
    </v-row>
  </v-form>
</template>

<script>
import emailjs from 'emailjs-com';
import{ init } from 'emailjs-com';
init("user_6eP4AEwl9TmJabYtuZEYL");
  export default {
    data: () => ({
      valid: true,
      vorname: '',
      vornameRules: [
        v => !!v || 'Vorname muss angegeben werden',
      ],
      nachname: '',
      nachnameRules: [
        v => !!v || 'Nachname muss angegeben werden',
      ],
      email: '',
      emailRules: [
        v => !!v || 'E-Mail oder Handynummer muss angegeben werden',
        // v => /.+@.+\..+/.test(v) || 'E-mail must be valid',
      ],
      select: null,
      snackbar: false,
      anfrage: ''
    }),

    props:['anfrageArt'],

    methods: {
      validate () {
        event.preventDefault;
        this.snackbar=true;
        this.$refs.form.validate()

        try {
        emailjs.send('service_r160bu9', 'template_35jf5ar',{
          vorname: this.vorname,
          nachname: this.nachname,
          email: this.email,
          nachricht: this.anfrage,
          art: "Anfrage " + this.anfrageArt,
          id:Date.now()},
        'user_6eP4AEwl9TmJabYtuZEYL', 
        )

      } catch(error) {
          console.log({error})
      }
      // Reset form field
      //this.vorname = '';
      //this.email = '';
      //this.nachname = '';
      //this.nachricht ='';
      },
    },
  }
</script>

<style>
</style>