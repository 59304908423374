<template>
    <v-content>
        <AnpassbareSeite :excelData="excelData" />
    </v-content>
</template>

<script>
import AnpassbareSeite from '../components/AnpassbareSeite.vue'
import $ from 'jquery'
export default {
    data(){
        return{
            excelDataValue:""
        }
    },
    components:{
        AnpassbareSeite
    },
    methods: {
      async aktualisieren(){
        var url = "https://sheets.googleapis.com/v4/spreadsheets/1YmLMMHUgaRV9GUSzhx8snUHJjw1MeXxthFmyVFK3sy4/values/UnsereHofkisten?alt=json&key=AIzaSyDGr9rBnm8lpmMY6D_lk3lvWI6Nq0IwZWA"
        $.ajaxSetup({
        async: false
        });
        var jsony;
        await ($.getJSON(url, 'callback=?')).always(function(data) {
            jsony = data.values;
            jsony.shift()
        });
        this.excelDataValue = jsony;
      }
    },
    beforeMount(){
        this.aktualisieren()
    },
    computed: {
      excelData: function(){
        return this.excelDataValue
      }
    },
}
</script>