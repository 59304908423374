<template>
    <v-content>
        <v-carousel 
        
        cycle
        height="250"
        hide-delimiter-background
        interval="4000"
        show-arrows-on-hover>
          <v-carousel-item
            v-for="(item,i) in items"
            :key="i"
            :src="item.src"
            reverse-transition="scale-transition"
            transition="slide-y-reverse-transition"
          ></v-carousel-item>
        </v-carousel>
        <AnpassbareSeite :excelData="excelData" />
        <v-container>
            <Anfragen :anfrageArt="''" />
        </v-container>
        <div class="googlemaps">
          <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d11668.970158429376!2d7.848722382536772!3d52.05972474310235!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47b99576a36d17ef%3A0xd06a7f3bac5c6fba!2sBrock%201%2C%2048346%20Ostbevern!5e0!3m2!1sde!2sde!4v1605296921028!5m2!1sde!2sde" width="100%" height="350" frameborder="0" style="border:0;" allowfullscreen="" aria-hidden="false" tabindex="0"></iframe>
        </div>
    </v-content>
</template>

<script>
import Anfragen from "../components/Anfragen"
import $ from 'jquery'
import AnpassbareSeite from '../components/AnpassbareSeite.vue'
import * as Sentry from "@sentry/vue";
export default {
    name:"Startseite",

    components: {
        Anfragen,
        AnpassbareSeite
    },
    methods: {
      async aktualisieren(){
        var url = "https://sheets.googleapis.com/v4/spreadsheets/1YmLMMHUgaRV9GUSzhx8snUHJjw1MeXxthFmyVFK3sy4/values/Startseite?alt=json&key=AIzaSyDGr9rBnm8lpmMY6D_lk3lvWI6Nq0IwZWA"
        $.ajaxSetup({
        async: false
        });
        var jsony;
        await ($.getJSON(url, 'callback=?')).always(function(data) {
            jsony = data.values;
            jsony.shift()
        });
        this.excelDataValue = jsony;
      }
    },
    beforeMount(){
        this.aktualisieren()
    },
    mounted(){
      Sentry.captureMessage("Neuer Besuch der Startseite");
    },
    computed: {
      excelData: function(){
        return this.excelDataValue
      }
    },
    data() {
        return{
            items: [
      {
        src: require('../assets/Tierbilder/Carousel2.jpg'),
      },
      {
        src: require('../assets/Tierbilder/Carousel6.jpg'),
      },
      {
        src: require('../assets/Tierbilder/Carousel3.png'),
      },
      {
        src: require('../assets/Tierbilder/Carousel4.jpg'),
      },
      {
        src: require('../assets/Tierbilder/Carousel5.png'),
      },
      {
        src: require('../assets/Tierbilder/Carousel1.png'),
      },
    ],
    excelDataValue: ""
        };
    }
}; 
</script>

<style>
.hintergrund{
    background-color:transparent;
}
/**border: 1px solid #000000;
    box-sizing: border-box;
 */
.zentrieren{
  align-self: center;
}
.zentralerInhalt{
  align-content: center;
}
.weißen{
  color: white;
}
#Philosophie{
  width: 700px;
}

</style>